import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import MichaelImg from '../images/Michael-Mueller-Registered-Representative-Coastal-Advisory-Group_480px.png';

import Layout from "../components/layout"
import SEO from "../components/seo"

const MichaelMuellerPage = (props) => (
    <Layout location={props.location}>
      <SEO title="Michael Mueller, President Coastal Advisory Group" description="Michael has garnered a reputation for being a highly personal and proactive wealth manager...you become part of the Coastal family..."/>
      <h1 style={styles.header}>Michael Mueller, President</h1>
      <div style={styles.body}>
        <p><a href="tel:+15136191100">513-619-1100</a> Ext. 6</p>
        <Container fluid={true}>
          <Row>
            <Col lg={3}><img src={MichaelImg} /></Col>
            <Col>
              As captain of the crew of the Coastal Advisory Group, Michael Mueller has garnered a reputation for being a 
              highly personal and proactive wealth manager. When you become a client, you become part of the Coastal family. 
              This provides you unparalleled access to him, regardless of what you need to discuss. Michael's personal mission 
              is to help create freedom, independence and peace of mind through unique client-centric solutions. He believes that 
              financial freedom has no age limit and that sometimes simple solutions can be found for complex situations.
            </Col>
          </Row>
          <p style={{paddingTop: '15px'}}>
            From aerospace engineering to oil and gas exploration to Silicon Valley high technology, Michael has 
            steered many ships through rocky waters within business sectors that drive our economy today. He provided 
            management consulting expertise to companies here and abroad.
          </p>
          <p>
            Michael understands the factors that drive your investment strategies from the inside out. He applies his 
            education in engineering, to incisively analyze the interplay of the factors that drive market success. 
            Michael meets with industry leaders and money managers on a regular basis to stay informed on worldwide 
            economic situations, and uses that knowledge to guide the financial life of his clients.
          </p>
          <p>
            Michael’s investment strategy also reflects his business expertise gained through company ownership. As the 
            Founder of the Coastal Advisory Group, and the former CEO of Coastal Equities, Inc., he views every wealth 
            management client as a small business. He knows the importance of how changing economic cycles and changing 
            world financial markets interact to impact any company’s performance—big or small. As a wealth manager, 
            Michael strives diligently to gain an in-depth understanding of each client’s needs and goals as thoroughly 
            as he understands those of businesses.
          </p>
          <p>
            Clients benefit from Michael’s use of an open architecture approach to investing. As an advisor with an independent firm, he can and does use any investment vehicle from any company that can help clients achieve their financial dreams. His fundamental philosophy is helping people live the one life they have without undue financial sacrifice or overexposure to risk. He fosters long-term relationships with families, and complements his advisory services with legacy and estate planning.
          </p>
          <p>
            Michael enjoys traveling to beautiful places to shoot nature photography, spending time with his wife, 
            a talented water color artist, and is supportive of his neighborhood association to help maintain and improve 
            the quality of life in his community.
          </p>
          <h6 style={{textAlign: 'center'}}>Check the background of this investment professional on <a rel="noopener noreferrer" target="_blank" href="https://brokercheck.finra.org/">FINRA's BrokerCheck</a></h6>
        </Container>
      </div>
    </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default MichaelMuellerPage
